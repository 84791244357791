.search-input {
    
}

.search-input > input {
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    color: #5e6278;
    transition: color .2s ease,background-color .2s ease;
    box-shadow: none!important;
    padding-left: 3.75rem!important;
    width: 250px !important;
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #181c32;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px  solid #e4e6ef;
    appearance: none;
    border-radius: 0.65rem;
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 250px!important;
  }