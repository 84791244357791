.font-20{
    font-size: 20px;
}

.blinking {
    -webkit-animation: 1s blink ease infinite;
    -moz-animation: 1s blink ease infinite;
    -ms-animation: 1s blink ease infinite;
    -o-animation: 1s blink ease infinite;
    animation: 1s blink ease infinite;
    
  }
  
  @keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  @-moz-keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  @-ms-keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  @-o-keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  .fa-bottombar-size{
    font-size: 15px !important;
  }

  .mobile-bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    will-change: transform;
    transform: translateZ(0);
    display: flex;
    height: 50px;
    box-shadow: 0 -2px 5px -2px #333;
    background-color: #fff;

 }
  .mobile-bottom-nav__item {
    flex-grow: 1;
    text-align: center;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #000 !important;
 }
  .mobile-bottom-nav__item--active {
    color: #ff620c!important;
 }

  .mobile-bottom-nav__item-content {
    display: flex;
    flex-direction: column;
 }
  


 /* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  /* Styles */
  #kt_aside_toggle{
    display:none;
  }
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
  /* Styles */
  #kt_aside_toggle{
    display:none;
  }
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
  /* Styles */
  #kt_aside_toggle{
    display:none;
  }
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  /* Styles */
  #kt_aside_toggle{
    display:none;
  }
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  /* Styles */
  #kt_aside_toggle{
    display:none;
  }
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  /* Styles */
  #kt_aside_toggle{
    display:none;
  }
  }
  /**********
  iPad 3
  **********/
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
  /* Styles */
  #kt_aside_toggle{
    display:none;
  }
  }
  
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
  /* Styles */
  .mobile-bottom-nav {
    display: none;
  }
  }
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1024px) {
  /* Styles */
  .mobile-bottom-nav {
    display: none;
  }
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
  /* Styles */
  .mobile-bottom-nav {
    display: none;
  }
  }

  
  
  .table-line-break{
    word-wrap: break-word;
    min-width: 160px;
    max-width: 160px;
  }
  
  .landing-btn{
    background-image: linear-gradient(to right top, #ff620c, #ff7f00, #ff9b00, #ffb400, #ffcd12);
    color:#000;
  }
 
  .font-30{
    font-size:30px;
  }

  .font-15{
    font-size:14px;
  }

  .font-25{
    font-size:25px;
  }
  .stats-card{
    box-shadow: 0 0 20px 0 rgb(76 87 125 / 16%);
   /* // width:60%; */
  }
  .icon-color{
color:#13263C;
  }


  
.chat-container {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.chat-container::after {
  content: "";
  clear: both;
  display: table;
}

.time-right {
  float: right;
  color: #999;
}
.time-right-admin {
  float: right;
  color: #fff;
}

.time-left {
  float: left;
  color: #999;
}

.darker {
  border-color: #ff620c;
  background-color: #ff620c;
  color: #fff;
}


.top-header-area {
  color: #fff;
  font-size: 15px;
  display: block;
  background: #ff343f !important;;
  text-align: center;
  padding: 10px 10px;
  top: 0px;
  left: 0px;
  right: 0px;
  
  z-index: 99999;
  height: 50px;
}

@media (max-width: 575px){
  .top-header-area {
      font-size: 12px!important;
      padding: 10px 5px!important;
  }
  }
  .h-60px{
    height:46px !important;
  }
  .w-60px{
    width:46px !important;
  }

  .br20{
    border-radius: 20px;
  }